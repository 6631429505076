<template>
  <ProductCellFieldProduct
    v-bind="$attrs"
    v-on="$listeners">
    <template #previewDialog="{ selectedColumnOrder, selectedRefFields, dialog, manageDialog, selectRefFields }">
      <AddingReference
        :items="selectedColumnOrder"
        :selected="selectedRefFields"
        :dialog="dialog"
        @manageDialog="manageDialog"
        @selectFields="selectRefFields" />
    </template>
  </ProductCellFieldProduct>
</template>
<script>
import ProductCellFieldProduct from '../ProductCellFieldProductBasic';
export default {
  name: 'ProductCellFieldProductReference',
  components: {
    ProductCellFieldProduct,
    AddingReference: () => import('./ProductCellFieldProductReferenceAdding'),
  },
};
</script>